import React from 'react';
import { graphql } from 'gatsby';
import { navigate } from '@reach/router';
import { Detail, Seo } from 'components';

export default ({
  data: {
    page: {
      frontmatter: { title, seo_title, redirect, tags, address, city, announce, description, type, order_title, order_title_hh, hh_link},
      body
    }
  }
}) => {
  if (redirect) {
    navigate(redirect);
  }
  return (
    <>
      <Seo
        title={seo_title || title + ' | вакансия в ' + (city == 'Санкт-Петербург' ? 'Санкт-Петербурге' : 'Москве')}
        description={
          description ||
          'Приглашаем на вакансию ' +
            title +
            ' в агентство Nimax в ' +
            (city == 'Санкт-Петербург' ? 'Санкт-Петербурге' : 'Москве')
        }
      />
      <Detail
        title={title}
        tags={tags ? tags.split(',') : []}
        address={address}
        city={city}
        job={true}
        announce={announce}
        type={type}
        order_title={order_title}
        order_title_hh={order_title_hh}
        hh_link={hh_link}
      >
        {body}
      </Detail>
    </>
  );
};

export const pageQuery = graphql`
  query($id: String!) {
    page: mdx(id: { eq: $id }) {
      frontmatter {
        seo_title
        title
        redirect
        tags
        city
        address
        announce
        description
        type
        order_title
        order_title_hh
        hh_link
      }
      body
    }
  }
`;

